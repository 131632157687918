import { map, tileLayer, control, latLng, geoJSON } from "leaflet";

import { basemaps } from "./basemaps";
import { drawPieChart } from "./charts";
import { observationStyle } from "./mapStyles";
import { GEO_HOST } from "./constants";
import { MapViewer } from "./mapViewer";

let mapViewer,
  navMap,
  layerControl,
  countryLayer,
  provinceLayer,
  districtLayer,
  segmentsLayer,
  observationLayer,
  overlaysGroup = {},
  baseLayerGroup = {};

// Initialize styles for the layers
const roadStyle = {
  color: "#ff7700",
  weight: 5,
  opacity: 0.9,
};

const mapDiv = document.getElementById("dvw:map_viewer");
if (mapDiv) {
  mapViewer = new MapViewer("dvw");
  navMap = mapViewer.getMap();
  layerControl = mapViewer.layerControl;

  mapViewer.setBaseMap("arcgisTerrain");
  layerControl.addTo(navMap);

  // control that shows state info on hover
  const info = L.control({ position: "bottomright" });

  info.onAdd = function (map) {
    this._div = L.DomUtil.create(
      "div",
      "info p-4 bg-white rounded-lg shadow dark:bg-gray-700 text-gray-900 dark:text-white"
    );
    this.update();
    return this._div;
  };

  info.update = function (props, evt) {
    const contents = props
      ? `
          <b>${new Date(props.obs_date).toDateString()}</b>
          <br/>
          Segment ID: ${props.segment_id}
          <br/>
          Observation Type: ${props.ob_type}
          <br/>
      `
      : "Hover over a point";
    this._div.innerHTML = `<h3 class="mb-2 text-xl font-bold text-gray-900 dark:text-white">Observation Point</h4>${contents}<br/><div id="obs-pie-chart"></div>`;
    if (props) {
      let series = [];
      let labels = [];
      if (props.crop_perc1) {
        series.push(props.crop_perc1);
        labels.push(props.crop_name1);
      }
      if (props.crop_perc2) {
        series.push(props.crop_perc2);
        labels.push(props.crop_name2);
      }
      if (props.crop_perc3) {
        series.push(props.crop_perc3);
        labels.push(props.crop_name3);
      }
      if (props.crop_perc4) {
        series.push(props.crop_perc4);
        labels.push(props.crop_name4);
      }
      if (props.crop_perc5) {
        series.push(props.crop_perc5);
        labels.push(props.crop_name5);
      }
      let data = {
        series: series,
        labels: labels,
      };
      drawPieChart(data, "obs-pie-chart", 300);

      // var mapWidth = navMap._container.offsetWidth;
      // var mapHeight = navMap._container.offsetHeight;

      // this._div.style.right = `${
      //   mapWidth - evt.containerPoint.x - this._div.offsetWidth - 50
      // }px`;
      // this._div.style.bottom = `${
      //   mapHeight - evt.containerPoint.y - this._div.offsetHeight - 50
      // }px`;
    } else {
      this._div.style.right = "unset";
      this._div.style.bottom = "unset";
    }
  };

  function highlightFeature(e) {
    const layer = e.target;
    layer.setStyle({
      weight: 5,
      color: "#666",
      dashArray: "",
      fillOpacity: 0.7,
      strokeOpacity: 1,
    });

    layer.bringToFront();

    info.update(layer.feature.properties, e);
  }

  provinceLayer = tileLayer
    .wms(GEO_HOST + "/geoserver/wms?", {
      layers: "mapfra:province",
      format: "image/png",
      transparent: true,
      tileSize: 1024,
    })
    .addTo(navMap);

  layerControl.addOverlay(provinceLayer, "Province");

  districtLayer = tileLayer
    .wms(GEO_HOST + "/geoserver/wms?", {
      layers: "mapfra:district",
      format: "image/png",
      transparent: true,
    })
    .addTo(navMap);

  layerControl.addOverlay(districtLayer, "District");

  overlaysGroup["Districts"] = districtLayer;

  segmentsLayer = tileLayer
    .wms(GEO_HOST + "/geoserver/wms?", {
      layers: "mapfra:segments",
      format: "image/png",
      transparent: true,
    })
    .addTo(navMap);

  layerControl.addOverlay(segmentsLayer, "Segments");

  overlaysGroup["Segments"] = segmentsLayer;

  $.getJSON(
    GEO_HOST +
      "/geoserver/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=mapfra:obs_point&outputFormat=application/json&srsName=EPSG:4326",
    {
      CQL_FILTER:
        "crop_perc1 IS NOT NULL OR crop_perc2 IS NOT NULL OR crop_perc3 IS NOT NULL OR crop_perc4 IS NOT NULL OR crop_perc5 IS NOT NULL",
    },
    function (response) {
      observationLayer = geoJSON(response, {
        pointToLayer: function (feature, latlng) {
          return new L.CircleMarker(latlng, observationStyle(feature));
        },
        onEachFeature: onEachFeature,
        interactive: false,
      }).addTo(navMap);

      navMap.fitBounds(observationLayer.getBounds());
      overlaysGroup["Observations"] = observationLayer;
      layerControl.addOverlay(observationLayer, "Observations");

      function resetHighlight(e) {
        observationLayer.resetStyle(e.target);
        info.update();
      }

      function zoomToFeature(e) {
        navMap.fitBounds(e.target.getBounds());
      }

      function onEachFeature(feature, layer) {
        layer.on({
          mouseover: highlightFeature,
          mouseout: resetHighlight,
          click: zoomToFeature,
        });
      }
      info.addTo(navMap);
    }
  );
}
